import axios from 'axios';
import { useState, useEffect } from 'react';
import { GraphData } from '../..';

const useGraphData = (players: string[]) => {
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  useEffect(() => {
    (async () => {
      const { data }: { data: GraphData[] } = await axios.post(`${process.env.REACT_APP_API_URL}/graphData`,
        players);
      setGraphData(data);
    })()
  }, [players])
  return graphData
}

export default useGraphData;