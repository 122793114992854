import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Card } from '@material-ui/core';
import style from './ProGraph.module.css';
import { GraphData } from '../..';
import moment from 'moment';

const ProGraph = ({ data }: { data: GraphData[] }) => {
  const graph = data ? (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 60, bottom: 150, left: 60 }}
      curve="monotoneX"
      colors={{ scheme: 'nivo' }}
      lineWidth={2}
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: 'linear',
        stacked: false,
        min: 'auto'
      }}
      axisBottom={{
        format: '%b %d',
        legend: '',
        tickRotation: 54
      }}
      enablePointLabel={false}
      useMesh={true}
      tooltip={({ point }) => {
        return <div>{`${point.serieId} ${point.data.y} (${moment(point.data.x).format('MMM D')})`}</div>
      }}
      enableSlices={false}
      legends={[
        {
          anchor: 'bottom-left',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: 140,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  ) : '';
  return (
    <Card className={style.chart}>
      {graph}
    </Card>
  );
}

export default ProGraph