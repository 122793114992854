import React, { useState, useEffect } from "react";
import { Card, IconButton } from "@material-ui/core";
import style from "./AtpList.module.css";
import { Link } from "react-router-dom";
import useAtp from "./useAtp";
import ProGraph from "./ProGraph";
import useGraphData from "./useGraphData";
import ShowChartIcon from "@material-ui/icons/ShowChart";

function AtpList({ league }: { league: string }) {
  const [graphPlayers, setGraphPlayers] = useState<string[]>([]);
  const { ratings } = useAtp(league);
  const graphData = useGraphData(graphPlayers);

  useEffect(() => {
    setGraphPlayers(ratings.slice(0, 5)?.map((r) => r.playerId));
  }, [league, ratings]);

  let list = ratings.map((rating, key) => (
    <div className={style.line} key={key}>
      <IconButton
        className={style.graphButton}
        onClick={() => {
          if (graphPlayers.includes(rating.playerId)) {
            graphPlayers.splice(graphPlayers.indexOf(rating.playerId), 1);
            setGraphPlayers([...graphPlayers])
          } else {
            setGraphPlayers([...graphPlayers, rating.playerId])
          }
        }}
      >
        <ShowChartIcon color={graphPlayers.includes(rating.playerId) ? 'secondary' : 'disabled'} />
      </IconButton>
      <div className={style.rank}>{key + 1}</div>
      <Link to={`/pro/player/${rating.playerId}`} key={key}>
        <img
          className={style.image}
          src={`https://storage.googleapis.com/players-images/${rating.playerId}.jpeg`}
          alt=""
        ></img>
      </Link>
      <div className={style.name}>
        <Link to={`/pro/player/${rating.playerId}`} key={key}>
          {rating.playerName}
        </Link>
      </div>
      <div className={style.rating}>{Math.round(rating.rating)}</div>
      <div className={style.diff}>
        {rating?.twoWeeksAgo[0]?.timestamp !== rating?.timestamp
          ? `(${Math.round(rating?.rating - rating?.twoWeeksAgo[0]?.rating)})`
          : ""}
      </div>
    </div>
  ));

  return (
    <>
      <Card className={style.main}>{list}</Card>
      <ProGraph data={graphData}></ProGraph>
    </>
  );
}

export default AtpList;
