import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import moment from 'moment';
import { Card, IconButton } from '@material-ui/core';
import style from './ProPlayer.module.css';
import usePlayerMatches from './usePlayerMatches';
import useGraphData from "./useGraphData";
import ProGraph from './ProGraph';
import ShowChartIcon from "@material-ui/icons/ShowChart";


const ProPlayer = () => {
    const { playerId }: { playerId: string } = useParams();
    const [graphPlayers, setGraphPlayers] = useState([playerId]);
    useEffect(() => {
        setGraphPlayers([playerId])
    }, [playerId])
    const graphData = useGraphData(graphPlayers);
    const matches = usePlayerMatches(playerId);
    const htmlMatches = matches.map((match) => {
        const player1Win = match?.player1?.is_winner ? (
            <img className={style.ball} src='/images/tennis-ball.png' alt='winner'></img>
        ) : '';
        const player2Win = match?.player2?.is_winner ? (
            <img className={style.ball} src='/images/tennis-ball.png' alt='winner'></img>
        ) : '';
        return (
            <Card className={style.match} key={match._id}>
                <div>
                    {match.tournamentId}
                </div>
                <div>
                    {moment(match?.timestamp.slice(0, 10)).fromNow()} ({moment(match?.timestamp.slice(0, 10)).format('LL')})
                </div>
                <div className={style.matchHeader}>
                    <img className={style.image} src={`https://storage.googleapis.com/players-images/${match?.player1?.id}.jpeg`} alt='' ></img>
                    <img className={style.image} src={`https://storage.googleapis.com/players-images/${match?.player2?.id}.jpeg`} alt='' ></img>
                </div>
                <div className={style.matchPlayer}>
                    <IconButton
                        className={style.graphButton}
                        onClick={() => {
                            if (graphPlayers.includes(match.player1.id)) {
                                graphPlayers.splice(graphPlayers.indexOf(match.player1.id), 1);
                                setGraphPlayers([...graphPlayers])
                            } else {
                                setGraphPlayers([...graphPlayers, match.player1.id])
                            }
                        }}
                    >
                        <ShowChartIcon color={graphPlayers.includes(match.player1.id) ? 'secondary' : 'disabled'} />
                    </IconButton>

                    <div>
                        <Link to={`/pro/player/${match.player1.id}`}> {match?.player1?.name} {Math.round(match?.player1?.rating?.rating || 0)} ({Math.round((match?.player1?.rating?.rating || 0) - (match?.player1?.rating?.oldRating || 0))}) {player1Win}
                        </Link>
                    </div>
                    <div className={style.sets}>
                        <div className={style.setPoints}>
                            {match?.player1?.set_games[0]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player1?.set_games[1]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player1?.set_games[2]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player1?.set_games[3]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player1?.set_games[4]}
                        </div>
                    </div>
                </div>
                <div className={style.matchPlayer}>
                    <IconButton
                        className={style.graphButton}
                        onClick={() => {
                            if (graphPlayers.includes(match.player2.id)) {
                                graphPlayers.splice(graphPlayers.indexOf(match.player2.id), 1);
                                setGraphPlayers([...graphPlayers])
                            } else {
                                setGraphPlayers([...graphPlayers, match.player2.id])
                            }
                        }}
                    >
                        <ShowChartIcon color={graphPlayers.includes(match.player2.id) ? 'secondary' : 'disabled'} />
                    </IconButton>
                    <div>
                        <Link to={`/pro/player/${match.player2.id}`}>{match?.player2?.name} {Math.round(match?.player2?.rating?.rating || 0)} ({Math.round((match?.player2?.rating?.rating || 0) - (match?.player2?.rating?.oldRating || 0))}) {player2Win}
                        </Link>
                    </div>
                    <div className={style.sets}>
                        <div className={style.setPoints}>
                            {match?.player2?.set_games[0]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player2?.set_games[1]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player2?.set_games[2]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player2?.set_games[3]}
                        </div>
                        <div className={style.setPoints}>
                            {match?.player2?.set_games[4]}
                        </div>
                    </div>
                </div>
            </Card>
        )
    })

    return (
        <div className={style.main}>
            <ProGraph data={graphData}></ProGraph>
            {htmlMatches}
        </div>
    )
}

export default ProPlayer