import React from 'react';
import styles from './App.module.css';
import AtpList from './components/pros/AtpList';
import ProPlayer from './components/pros/ProPlayer';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {
  Switch,
  Route,
  useLocation,
  useHistory
} from "react-router-dom";

function App() {
  const location = useLocation();
  const history = useHistory();
  const navigation = location.pathname.substring(1);

  return (
      <>
      <Switch>
        <Route path='/pro/player/:playerId'>
          <ProPlayer></ProPlayer>
        </Route>
        <Route path={['/', '/atp', 'wta']}>
          <div className={styles.content}>
            <AtpList league={navigation || 'atp'} />
          </div>
        </Route>
      </Switch>
      <BottomNavigation
        value={navigation}
        onChange={(event, newValue) => {
          history.push(`/${newValue}`);
        }}
        showLabels
        className={styles.navigation}
      >
        <BottomNavigationAction label="ATP" value='atp' />
        <BottomNavigationAction label="WTA" value='wta' />
      </BottomNavigation>
      </>
  );
}

export default App;



