import axios from 'axios';
import { useState, useEffect } from 'react';
import { Match } from '../..';

const usePlayerMatches = (playerId:string): Match[] => {
  const [matches, setMatches] = useState<Match[]>([]);
  useEffect(() => {
    (async() => {
      const {data}: {data: Match[]} = await axios.get(`${process.env.REACT_APP_API_URL}/playerMatches/${playerId}`);
      setMatches(data);
    })()
  }, [playerId])
  return matches
}

export default usePlayerMatches;