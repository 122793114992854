import axios from 'axios';
import { useState, useEffect } from 'react';
import {Rating} from '../..';

const useAtp = (league:string) => {
  const [ratings, setRatings] = useState<Rating[]>([]);
  useEffect(() => {
    (async () => {
      const { data }: { data: Rating[] } = await axios.get(`${process.env.REACT_APP_API_URL}/${league}`);
      setRatings([...data]);
    })()
  }, [league])
  return { ratings }
}

export default useAtp;